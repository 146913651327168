import React from 'react';
import MetaTags from 'react-meta-tags';
import MintButton from '../Components/MintButton';
import RugzHomeHumor from '../Components/RugzHomeHumor';

export default function About() {
  return (
    <>
      <MetaTags>
        <link rel="canonical" href="https://notoriousrugs.io/About"></link>
        <title>"NotoriousRugs - About"</title>
        <meta
          name="description"
          content="6969 free-to-low-cost NotoriousRug NFTs, inspired by numerous appalling rug pulls, are available to collectors. The first 420 NFTs are completely free, while the rest are offered at an almost-free price. Our mission is to provide users with an enjoyable experience and valuable education on their NFT journey."
        />
        <meta name="keywords" content="Free, NFT"></meta>
        <meta property="og: locale" content="es_ES" />
        <meta
          property="og:title"
          content="NotoriousRugs - a collection fo Free NFTs"
        />
        <meta
          property="og:description"
          content="6969 free-to-low-cost NotoriousRug NFTs, inspired by numerous appalling rug pulls, are available to collectors. The first 420 NFTs are completely free, while the rest are offered at an almost-free price. Our mission is to provide users with an enjoyable experience and valuable education on their NFT journey."
        />
        <meta property="og:url" content="https://notoriousrugs.io" />
        <meta
          property="og:image"
          content="https://notoriousrugs.io/images/rugs8.webp"
        />
        <meta property="og:type" content="website" />
      </MetaTags>
      <div className="pt-5 text-white text-muted text-break text-wrap text-center">
        <section>
          <h1 className="font-weight-bold text-info">FAQs</h1>
        </section>
        <section>
          <h4 className="font-weight-bold text-warning">Is this a rug pull?</h4>
          <p>
            Head over to etherscan and check out our contract{' '}
            <a href="https://etherscan.io/address/0xf567d57b1450258ebbcfa323798a0ef90d081aef#code">
              0xf567d57b1450258ebbcfa323798a0ef90d081aef
            </a>
            . You&apos;ll find it&apos;s safe!
          </p>
          <p>
            We provide free to low cost NFTs to help you understand the concepts
            of a DAPP, GAS and general crypto concepts
          </p>
        </section>
        <section>
          <h4 className="text-warning">DAPP/GAS</h4>
          <p>
            A <strong className="text-warning">DAPP</strong> can be a page
            within a website or a standalone single page website.
          </p>
          <p>
            The purpose of the <strong className="text-warning">DAPP</strong> is
            to interact with the NFT contract, think of it as the checkout cart.
          </p>
          <p>
            <strong className="text-warning">GAS </strong> is the{' '}
            <span className="text-warning">fee paid </span> to use the Ethereum
            network. The<strong className="text-warning"> GAS </strong> is
            'demand pricing' depending on the network traffic.
          </p>

          <h4 className="text-warning">Coin Prices</h4>
          <p>
            Our main page provides the current price of 3 popular coins in 1
            location.
          </p>
          <p>
            The{' '}
            <MintButton
              btnText={'DAPP'}
              btnId={'dapp'}
              btnSize={'sm'}
              btnClass={'ml-1 p-0'}
            />{' '}
            relies on the API to return the current ETH price to convert the
            payable to USD.
          </p>
          <p>
            Whereas, the contract deals only with ETH. The estimated USD price
            is for your convenience.
          </p>
          <p>
            You can always mint directly from the contract{' '}
            <a href="https://etherscan.io/address/0xf567d57b1450258ebbcfa323798a0ef90d081aef#code">
              0xf567d57b1450258ebbcfa323798a0ef90d081aef
            </a>
            .{' '}
          </p>
          <p>
            The coin prices start at an initial default state of 1 and are
            updated every minute using industry standard API protocols.
          </p>
          <p>
            If you see a coin price of 1, it's an indication of a failed API
          </p>
          <p>
            Failed APIs are out of our control. Occasionally the API fails but
            eventually prices are returned though the time frame varies
          </p>
        </section>
        <section>
          <h4 className="text-warning">Having some fun with CSS animations</h4>
          <RugzHomeHumor />
        </section>
      </div>
    </>
  );
}
