import React from "react";
import MetaTags from 'react-meta-tags';
import RugzHome from "../Components/RugzHome";
// import RugzFooter from "../Components/RugzFooter";
// import Slideshow from "../Components/SlideShow";

export default function Home() {
  return (
    <>
      <MetaTags>
        <link rel="canonical" href="https://notoriousrugs.io/Partners"></link>
        <title>"NotoriousRugs - Home"</title>
        <meta
          name="google-site-verification"
          content="_38ouHz07qzzhb4uwgZSWQpgghnmVLFVG8K6Q5CFI8g"
        />
        <meta
          name="description"
          content="6969 free-to-low-cost NotoriousRug NFTs, inspired by numerous appalling rug pulls, are available to collectors. The first 420 NFTs are completely free, while the rest are offered at an almost-free price. Our mission is to provide users with an enjoyable experience and valuable education on their NFT journey."
        />
        <meta name="keywords" content="Free, NFT"></meta>
        <meta property="og: locale" content="es_ES" />
        <meta
          property="og:title"
          content="NotoriousRugs - a collection fo Free NFTs"
        />
        <meta
          property="og:description"
          content="6969 free-to-low-cost NotoriousRug NFTs, inspired by numerous appalling rug pulls, are available to collectors. The first 420 NFTs are completely free, while the rest are offered at an almost-free price. Our mission is to provide users with an enjoyable experience and valuable education on their NFT journey."
        />
        <meta property="og:url" content="https://notoriousrugs.io" />
        <meta
          property="og:image"
          content="https://notoriousrugs.io/images/rugs8.webp"
        />
        <meta property="og:type" content="website" />
      </MetaTags>
      <div className="Home-header-background pl-2">
        <RugzHome />
        {/* <RugzFooter /> */}
      </div>
    </>
  );
  
}
